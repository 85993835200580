import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { useAnalyticsService } from 'admin-portal-shared-services';
import { useEffect } from 'react';
import { EnvConfig, EnvProvider } from './components';
import { IntlProvider } from './i18n';
import Router from './Router';

export default function App(props: EnvConfig) {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-ai-tool-mfe',
    seed: 'bees-hub-ai-tool-mfe',
  });

  const analyticsService = useAnalyticsService();

  useEffect(() => {
    if (props.segmentKey) {
      analyticsService?.load(props.segmentKey);
    }
  }, [analyticsService, props.segmentKey]);

  return (
    <EnvProvider env={props}>
      <StylesProvider generateClassName={generateClassName}>
        <IntlProvider>
          <Router />
        </IntlProvider>
      </StylesProvider>
    </EnvProvider>
  );
}
