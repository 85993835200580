import { LoadingDots } from '@hexa-ui/components';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const ChatPage = lazy(() => import('./pages/ChatPage/ChatPage'));

const BASENAME = 'hub-ai-tool';
const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={`${BASENAME}/`}
          element={
            <Suspense fallback={<LoadingDots />}>
              <ChatPage />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
